<template>
  <div>
    <div>
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <h2>Checkout</h2>
            <ul>
              <li><a href="index.html">Home</a></li>
              <li class="active">Checkout</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="checkout-area container">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-12">
              <form action="javascript:void(0)">
                <div class="checkbox-form">
                  <h3>Details</h3>
                  <div>
                    <div class="contact-info-checkout">
                      <ul class="checkoutUl">
                        <li class="checkoutLi">
                          <div class="circle"><i class="fas fa-user"></i></div>
                          Barry Allen
                        </li>
                        <li class="checkoutLi">
                          <div class="circle"><i class="fas fa-phone"></i></div>
                          +0 000 0000000
                        </li>
                        <li class="checkoutLi">
                          <div class="circle">
                            <i class="fas fa-envelope"></i>
                          </div>
                          dummy@email.com
                        </li>
                        <li class="checkoutLi">
                          <div class="circle">
                            <i class="fas fa-map-marker"></i>
                          </div>
                          Panampilly Nagar<br />
                          near State Bank of India<br />
                          Ernakulam, Kerala
                        </li>
                        <hr />
                        <li class="checkoutLi">
                          <div class="circle"><b>Order ID:</b></div>
                          <b>#089368n9y63gh883</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-6 col-12">
              <div class="your-order">
                <h3>Your Purchase Details</h3>
                <div class="your-order-table table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="cart-product-name">Product</th>
                        <th class="cart-product-total">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="cart_item">
                        <td class="cart-product-name">
                          Vestibulum suscipit<strong class="product-quantity">
                            × 1</strong
                          >
                        </td>
                        <td class="cart-product-total">
                          <span class="amount">£165.00</span>
                        </td>
                      </tr>
                      <tr class="cart_item">
                        <td class="cart-product-name">
                          Vestibulum suscipit<strong class="product-quantity">
                            × 1</strong
                          >
                        </td>
                        <td class="cart-product-total">
                          <span class="amount">£165.00</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="cart-subtotal">
                        <th>Subtotal</th>
                        <td><span class="amount">£215.00</span></td>
                      </tr>
                      <tr class="cart-subtotal">
                        <th>Tax (7%)</th>
                        <td><span class="amount">£215.00</span></td>
                      </tr>
                      <tr class="order-total">
                        <th>Total</th>
                        <td>
                          <strong><span class="amount">£215.00</span></strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="payment-method">
                  <div class="payment-accordion">
                    <div id="accordion">
                      <div class="card">
                        <div class="card-header" id="#payment-1">
                          <h5 class="panel-title">
                            <a
                              href="javascript:void(0)"
                              class=""
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Direct Bank Transfer
                              <i class="fas fa-chevron-down"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          class="collapse show"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <p>
                              Make your payment directly into our bank account.
                              Please use your Order ID as the payment reference.
                              Your order won’t be shipped until the funds have
                              cleared in our account.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header" id="#payment-2">
                          <h5 class="panel-title">
                            <a
                              href="javascript:void(0)"
                              class="collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Cheque Payment <i class="fas fa-chevron-down"></i>
                            </a>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          class="collapse"
                          data-parent="#accordion"
                        >
                          <div class="card-body">
                            <p>
                              Make your payment directly into our bank account.
                              Please use your Order ID as the payment reference.
                              Your order won’t be shipped until the funds have
                              cleared in our account.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pMethod">
                      <span>Choose a payment method</span>
                      <div class="mLeft">
                        <input type="checkbox" name="choice" id="cb1" /><label
                          for="cb1"
                          >Bank Tranfer</label
                        >
                        <input
                          type="checkbox"
                          name="choice"
                          id="cb2"
                          class="mLeft"
                        /><label for="cb2">Online Payment</label>
                      </div>
                    </div>
                    <div class="order-button-payment">
                      <input value="Confirm Order" type="submit" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.your-order .your-order-table table thead tr th,
.your-order .your-order-table table thead tr td {
  border-bottom: 1px solid #e5e5e5!important;
  border-right: medium none;
  font-size: 14px;
  padding: 15px 0;
  text-align: unset;
}
</style>
<script>
export default {
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
     
    }
  },
   mounted() {
    if (!this.loggedIn) {
      this.$router.push('/').catch(()=>{"checkout"});
    }
  },
}
</script>
